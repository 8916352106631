import * as React from "react"

import { useIntl } from "gatsby-plugin-react-intl"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Header from '../components/header'
import ProductTile from "../components/ProductTile"
import Footer from "../components/footer"

import BackIcon from '../../static/ic_icon_go_back_black.svg'

import '../styles/global.css'

import styled from "styled-components"

const ShopPage = ({ location }) => {
  const intl = useIntl()
  const locale = intl.locale

  return (
    <main>
        <div style={{zIndex:'2',position:'relative',display:'inline-block',marginBottom:'400px',width:"100%",backgroundColor:'#F2F2F2'}}>
            <Header/>
            {/* <Breadcrumb location={location} crumbLabel="Shop" /> */}
            <div style={{borderTop:'solid #DDDDDD 1px',borderBottom:'solid #DDDDDD 1px'}}>
              <StyledLink style={{color:'#272424',fontSize:'17px',textDecoration:'none',padding:'20px 40px 20px 30px',borderRight:'solid #DDDDDD 1px'}} fade to="/">
                <img src={BackIcon} style={{width:'100%',maxWidth:'20px',opacity:0.9,top:'0px',paddingRight:'12px'}} alt='bijour'/>
                {"Pendu du Design"}
                </StyledLink>
            </div>
            <ProductTile />
            <Spacer/>
        </div>
        <div style={{position:'fixed',bottom:'0',width:"100%"}}>
          <Footer locale={locale}/>
        </div>
    </main>
  )
}

const Spacer = styled.div`
    height:120px;
    width:100%;
    background-color:#F2F2F2;
    border-top: solid #DDDDDD 1px;
    border-bottom: solid #DDDDDD 1px;
`

const StyledLink = styled(props => <AniLink {...props} />)`
  color:white;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  fontSize:17px;
  textDecoration:none;
  padding:60px;
  &:hover{
    background-color:white;
  }
`;

export default ShopPage

