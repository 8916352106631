import React, { useState } from "react"

import styled from "styled-components"

const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
  fontSize: "18px",
  fontStyle:'bold',
  textAlign: "center",
  color: "#000",
  textDecoration:"underline",
  backgroundColor:'white',
  borderRadius:'6px',
  width: 'fit-content',
  blockSize: 'fit-content',
  padding:'0px 20px 0px 20px',
  display:'flex',
  flexDirection:'row',
  justifyContent:'center',
  alignItems:'center',
  height:'36px',
}

const Checkout = () => {

  return (
    <StyledLink href="https://buy.stripe.com/4gwaH5de8cc3fvO4gh" style={{color:"black"}} className="checkoutButton">{"34.99€"}&nbsp;</StyledLink>
  )
}

const StyledLink = styled.a`
  font-size: 18px;
  font-style:bold;
  font-weight:600;
  text-align: center;
  color: #000;
  border-radius:6px;
  height:40px;
  padding:0px 20px 0px 20px;
  display:flex;
  flexDirection:row;
  justifyContent:center;
  alignItems:'center';
  &hover{
    text-decoration: underline;
    background-color: #DEDCD9;
  }
`;

export default Checkout