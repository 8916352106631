import React from 'react'

import styled from "styled-components"

const DetailsTile = ({Data}) => {

    const LegendeStyle =
    {
        fontSize:'11px',
        fontStyle: 'regular',
        fontWeight: '400',
        lineHeight:'16px',
        padding:'15px 0px 15px 0px'
    }

    return(
        <MainContainer>
            <div style={{
                display:'flex',
                width:'100%',
                flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
                height:'60px',
                marginTop:'30px',
                textAlign:'left'}}>
                <p style={LegendeStyle}>
                    * 29,7 x 42 cm. * 
                    Couleur Or sur papier noir 270g *
                    Risographie *
                    Colis cartonné adapté * 
                    Livraison : Relay colis. environ 2 semaines. * 
                    France Métropolitaine. * 
                    Quantité limitée. * 
                </p>
            </div>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display:flex;
    flex-direction:column;
`

const Legende = styled.p`
font-size:11px;
font-style: regular;
font-weight: 400;
line-height:16px;
background-Color:${({ isOpen }) => (isOpen ? "red" : "green")};
opacity:${({ isOpen }) => isOpen ? '0' : '1'};
`

export default DetailsTile