import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"

import Checkout from "./checkout"

import ArrowLeft from "../../static/ic_go_back_icon_big_black.svg"
import ArrowRight from "../../static/ic_go_right_icon_big_black.svg"
import IconPendu from "../../static/Icon-Marron.svg"

import DetailsTile from '../components/DetailsTile'

const galeryMobileHeight = '633px'
const mobileBreakingPoint = 724

const ProductTile = ({ locale }) => {

  const {Desktop,Mobile} = useStaticQuery(graphql`
  {
    Desktop:allFile(
        filter: {relativeDirectory: {eq: "Shop/SigneEmpire"}}
        sort: {fields: name}
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH,quality: 100)
          }
        }
      }
    Mobile:allFile(
        filter: {relativeDirectory: {eq: "Shop/SigneEmpire/Mobile"}}
        sort: {fields: name}
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH,quality: 100)
          }
        }
      }
  }
`)

    const [iter, setIter ] = useState(0)
    const [width, setWidth ] = useState(0)
    const len = Desktop.nodes.length
    const filledArray = Array(len).fill({ value: 0 });

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener("resize", () => {
          setWidth(window.innerWidth);
        });
        return () => {
          window.removeEventListener("resize", () => {});
        };
      }, []);

    const moveOn = () => {
        if((iter+1)> (len - 1)){
            setIter(0)
        } else {
            setIter(e=>e+1)
        }
    }

    const getBack = () => {
        if((iter-1) < 0){
            setIter((len - 1))
        } else {
            setIter(e=>e-1)
        }
    }

    const moveTo = (index) => {
        setIter(index)
    }

    return(
        <MainContainer>
            <LeftContainer>
                <LeftAbsoluteImageGalery style={{position:'relative',width:'100%',minHeight: galeryMobileHeight}}>
                    <div style={{
                        position:'absolute',
                        width:'100%',
                        height:'100%',
                        justifyContent:'center',
                        alignItems:'center',
                        }}>
                            {/* <GatsbyImage image={image} /> */}
                        { width <= mobileBreakingPoint && 
                            Mobile.nodes.map((node,id) => {
                                if(iter === id){
                                    return <GatsbyImage
                                    image={node.childImageSharp.gatsbyImageData}
                                    alt="Gallery"
                                    style={{height:'100%',width:'100%'}}
                                  />
                                } else {
                                    return <GatsbyImage
                                    image={node.childImageSharp.gatsbyImageData}
                                    alt="Gallery"
                                    style={{display:'none'}}
                                  />
                                }
                            })
                        }
                        { width > mobileBreakingPoint && 
                            Desktop.nodes.map((node,id) => {
                                if(iter === id){
                                    return <GatsbyImage
                                    image={node.childImageSharp.gatsbyImageData}
                                    alt="Gallery"
                                    style={{height:'100%',width:'100%'}}
                                  />
                                } else {
                                    return <GatsbyImage
                                    image={node.childImageSharp.gatsbyImageData}
                                    alt="Gallery"
                                    style={{display:'none'}}
                                  />
                                }
                            })
                        }
                    </div>
                    <div style={{position:'absolute',display:'flex',width:'100%',bottom:'0px',backgroundColor:'#F2F2F2'}}>
                        <div style={{ width:'50%', display: 'flex', alignItems:'center', height:'100%' }}>
                            <button onClick={()=>getBack()} style={{ width:'80px',height:'80px',borderRight:'solid #DDDDDD 1px' }}><img src={ArrowLeft} alt='bijour' style={{maxWidth:'32px',width:'100%',height:'auto',opacity:0.9}}/></button>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            {
                                filledArray.map((item,key) => {
                                    return(<button onClick={()=>moveTo(key)} key={key} style={{borderRadius:'18px'}}><img src={IconPendu} alt='bijour' style={{maxWidth:'32px',width:'100%',height:'auto',opacity:key === iter ? 0.9:0.2}}/></button>)
                                })
                            }
                        </div>
                        <div style={{ width:'50%', display: 'flex', alignItems:'center',justifyContent:'right' }}>
                            <button onClick={()=>moveOn()} style={{ width:'80px',height:'80px',borderLeft:'solid #DDDDDD 1px' }}><img src={ArrowRight} alt='bijour' style={{maxWidth:'32px',width:'100%',height:'auto',opacity:0.9}}/></button>
                        </div>
                    </div>
                </LeftAbsoluteImageGalery>
            </LeftContainer>
            <RightContainer>
                <div>
                <div style={{ display:'flex',paddingTop:'60px',paddingBottom:'60px' }}>
                    <div style={{width:'50%'}}>
                        <h2 style={{paddingBottom:'5px',margin:'0px',textWrap:'nowrap'}}>Affiche A3</h2>
                        <h4 style={{margin:'0px',fontStyle:'regular',fontWeight:'400'}}>Les signes<br/> de l’Empire</h4>
                    </div>
                    <div style={{width:'50%',display:'flex',justifyContent:'end',alignItems:'center'}}>
                        <Checkout />
                    </div>
                </div>
                <p style={{fontWeight:'400',fontSize:'14px',maxWidth:'420px',lineHeight:'23px'}}>
                    Cette planche regroupe les éléments de grammaire permettant d’identifier un mobilier au style Empire, 1804-1815. 
                    Égyptomanie, le style Empire est marqué par le retour des histoires Antiques Grec et Égyptienne.
                    César, Marc Antoine et Cléopâtre, personnages qui ont tant fasciné l'enfant Napoléon.<br/><br/>
                    Objet précieux. Encre couleur Or légèrement cuivré; est luisant sur un épais papier mat, au noir profond.
                </p>
                <DetailsTile />
                <Spacer/>
{/*                     <div style={{display:'flex',flexDirection:'row', paddingTop:'10px'}}>
                        <div style={{width:'30%'}}>
                            <Checkout />
                        </div>
                       <div style={{width:'80%',display:'flex',justifyContent:'end'}}>
                            <Legende>
                                29,7 x 42 cm <br />
                                Couleur Or sur papier noir 270g<br />
                                Risographie<br />
                                Colis cartonné adapté<br/>
                                Livraison : Relay colis<br /> environ 2 semaines<br />
                                France Métropolitaine<br />
                                En stock - Quantité limitée
                            </Legende>
                        </div> 
                    </div> */}
                </div>
            </RightContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display:flex;
    flex-direction:row;
    min-height:80vh;
    padding:0px;
    background-color:#F2F2F2;
    @media (max-width: 724px) {
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
`

const LeftContainer = styled.div`
    position:relative;
    width:50%;
    display:flex;
    text-align:center;
    justify-content:center;
    align-items:center;
    border-right:solid #DDDDDD 1px;
    background-color:#F2F2F2;
    @media (max-width: 724px) {
        width:100%;
        min-height:${galeryMobileHeight};
        border-right:solid #DDDDDD 0px;
        border-bottom:solid #DDDDDD 1px;
    }
`

const LeftAbsoluteImageGalery = styled.div`
    position:relative;
    width:100%;
    height:100%;
    @media (max-width: 724px) {
        minHeight:${galeryMobileHeight};
    }
`

const RightContainer = styled.div`
    display:flex;
    max-width:420px;
    flex-direction:column;
    justify-content:center;
    alignItems:center;
    margin:auto;
    padding: 40px 40px 40px 40px;
    @media (max-width: 724px) {
        min-height:633px;
        padding: 80px 40px 80px 40px;
    }
`

const Spacer = styled.div`
    height:60px
`

export default ProductTile